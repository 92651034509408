<template>
    <b-card body-class="pa-0">
      <Confirm
        title="Starta SPAR körning"
        message="Är du säker på att du vill starta SPAR körningen? Kostnad tillkommer."
        ref="confirmJob"
        :type="'success'"
        @confirm="onJobConfirmed"
      />

      <b-card title="SPAR körning alternativ" class="mb-8" v-if="showEditor">
        <b-form class="form" v-if="company" @submit.prevent="onSubmit">
          <b-row>
            <b-col>
              <b-form-group id="input-group-name" label="Namn på SPAR körningen" label-for="input-name">
                <b-form-input id="input-name" v-model="form.name" type="text"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          
          
          <b-row>
            <b-col>
              <b-form-group>
                <div>
                  <label class="radio radio-outline radio-success mb-4">
                    <input type="radio" v-model="form.company_selection" value="SELECTION" name="radio-option-companies"/>
                    <span style="margin-right: 12px;"></span>
                    Välj föreningar
                  </label>
                  <label class="radio radio-outline radio-success mb-4">
                    <input type="radio" v-model="form.company_selection" value="ALL" name="radio-option-companies" />
                    <span style="margin-right: 12px;"></span>
                    Alla föreningar
                  </label>
                </div>
              </b-form-group>
            </b-col>
          
            <b-col v-if="form.company_selection === 'SELECTION'">
            
              <b-form-group
                :label="$t('ADVANCED_SEARCH.COMPANIES')">

                <Multiselect
                  class="ml-2 multiselect-blue"
                  v-model="form.company_ids"
                  ref="companies_multiselect"
                  mode="tags"
                  :placeholder="$t('ADVANCED_SEARCH.ALL_COMPANIES')"
                  :close-on-select="false"
                  :filter-results="false"
                  :min-chars="0"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options="async function(query) {
                    return await fetch_company_options(query);
                  }"
                />

              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group>
                <div>
                  <label class="radio radio-outline radio-success mb-4">
                    <input type="radio" v-model="form.period_selection" value="SELECTION" name="radio-option-period"/>
                    <span style="margin-right: 12px;"></span>
                    Välj perioder
                  </label>
                  <label class="radio radio-outline radio-success mb-4">
                    <input type="radio" v-model="form.period_selection" value="ALL" name="radio-option-period" />
                    <span style="margin-right: 12px;"></span>
                    Alla perioder
                  </label>
                </div>
              </b-form-group>
            </b-col>

            <b-col v-if="form.period_selection === 'SELECTION'">
            
              <b-form-group
                :label="$t('ADVANCED_SEARCH.PERIODS')">

                <Multiselect
                  class="ml-2 multiselect-blue"
                  v-model="form.period_ids"
                  ref="periods_multiselect"
                  mode="tags"
                  :placeholder="$t('ADVANCED_SEARCH.ALL_PERIODS')"
                  :close-on-select="false"
                  :filter-results="false"
                  :min-chars="0"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options="async function(query) {
                    return await fetch_period_options(query);
                  }"
                />

              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SPAR.TARGET_PERIOD_ID')">

                <b-form-select
                  v-model="form.target_period_id"
                  :options="period_options"
                ></b-form-select>

            </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group id="input-group-num_lookups_calc" label="Antal uppslag" label-for="input-num_lookups_calc">
                <b-form-input id="input-name" :disabled="true" v-model="form.num_lookups_calc" type="text"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-group id="input-group-cost_sek" label="Estimerad kostnad" label-for="input-cost_sek">
                <b-form-input id="input-name" :disabled="true" v-model="form.cost_sek" type="text"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

            <b-row class="mt-8">
              <b-col>
                <b-button @click="saveSPARJob" type="submit" variant="primary">Spara</b-button>
                <b-button @click="startJobClicked" :disabled="isDisabledStart" class="ml-8" type="submit" variant="outline-success">Starta körning</b-button>
              </b-col>
            </b-row>
        </b-form>
      </b-card>

      <div class="card-toolbar text-right">
        <a class="btn btn-primary font-weight-bolder font-size-sm mb-8" @click="newSPARJobClicked"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>Ny SPAR körning</a
        >
      </div>

      <b-row>
        <b-col cols="6" >
          <div class="bottom-alignment">
            <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
          </div>

        </b-col>
        <b-col cols="6">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 500, 1000]"
              v-model="per_page"
            />

            <b-pagination
              class="mb-0 mt-2 align-bottom"
              style="padding-left: 0px !important;"
              v-model="current_page"
              :total-rows="total_rows"
              :per-page="per_page"
              first-number
              last-number
            />
          </div>
        </b-col>
      </b-row>

      <b-table
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        head-variant="light"
        hover
        :fields="spar_job_headers1"
        :items="spar_jobs"
        :per-page="per_page"
        :current-page="current_page"
      >
        <template #cell(action)="{ item }">
          <b-button
            type="button"
            class="btn btn-sm"
            @click="selectSPARJob(item.id)"
            variant="primary"
          >Hantera</b-button
          >
        </template>
      </b-table>

    </b-card>

</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'


export default {
  name: 'SPARJobTable',
  components: {
    Confirm,
    Multiselect
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies', 'settings']),
    period_options() {
      return this.periods.map(item => ({ text: item.name, value: item.id }));
    }
  },
  mounted() {
    this.loadSPARJobs();

    if (!this.company && this.companies) {
      this.company = this.companies.find(item => item.company_id === this.currentCompanyId);
    }

    if (!this.period && this.periods) {
      this.period = this.periods.find(item => item.id === this.currentPeriodId);
    }
  },
  data() {
    return {
      showEditor: false,
      period: null,
      company: null,
      showConfirm: true,
      form: {
        name: 'Ny körning',
        company_ids: [],
        period_ids: [],
        period_selection: 'SELECTION',
        company_selection: 'SELECTION',
        num_lookups_calc: 0,
        cost_sek: 0,
        target_period_id: null
      },
      spar_jobs: [],
      spar_job_headers: [
        {
          text: 'Skapat',
          align: 'start',
          sortable: true,
          value: 'created_at',
          thClass: 'td-overflow',
          tdClass: 'td-overflow',
        },
        {
          text: 'Avslutat',
          align: 'start',
          sortable: true,
          value: 'completed_at',
          thClass: 'td-overflow',
          tdClass: 'td-overflow',
        },
        {
          text: 'Kostnad',
          align: 'start',
          sortable: true,
          value: 'cost_sek'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status'
        },
        {
          text: '# Antal',
          align: 'start',
          value: 'num_lookups_calc'
        },
        {
          text: '# OK',
          align: 'start',
          value: 'num_ok'
        },
        {
          text: '# Ej hittade',
          align: 'start',
          value: 'num_not_found'
        },
        {
          text: '# Fel',
          align: 'start',
          value: 'num_errors'
        },
        {
          value: 'action'
        }
      ],
      spar_job_headers1: [
        {
          label: 'Skapat',
          align: 'start',
          sortable: true,
          key: 'created_at',
          thClass: 'td-overflow',
          tdClass: 'td-overflow',
        },
        {
          label: 'Avslutat',
          align: 'start',
          sortable: true,
          key: 'completed_at',
          thClass: 'td-overflow',
          tdClass: 'td-overflow',
        },
        {
          label: 'Kostnad',
          align: 'start',
          sortable: true,
          key: 'cost_sek'
        },
        {
          label: 'Status',
          align: 'start',
          sortable: true,
          key: 'status'
        },
        {
          label: '# Antal',
          align: 'start',
          key: 'num_lookups_calc'
        },
        {
          label: '# OK',
          align: 'start',
          key: 'num_ok'
        },
        {
          label: '# Ej hittade',
          align: 'start',
          key: 'num_not_found'
        },
        {
          label: '# Fel',
          align: 'start',
          key: 'num_errors'
        },
        {
          label: '',
          key: 'action',
          tdClass: 'text-right',
          thClass: 'text-right',
        }
      ],
      headerProps: {
        sortByText: 'Sortera'
      },
      current_page: 1,
      per_page: 100,
      total_rows: 0,
      activeExpanded: [],
      closedExpanded: [],
      singleExpand: false,
      rowsPerPage: [100, 200, 500, -1],
      search: null,
      isDisabledStart: false,
    };
  },
  watch: {
    'form.period_selection'(newValue) {
      this.isDisabledStart = true;
    },
    'form.company_selection'(newValue) {
      this.isDisabledStart = true;
    },
    companies(newValue) {
      this.company = newValue.find(item => item.company_id === this.currentCompanyId);
    },
    periods(newValue) {
      this.period = newValue.find(item => item.id === this.currentPeriodId);
    },
    spar_jobs(new_val) {
      this.total_rows = new_val.length
    },
  },
  methods: {

    async fetch_period_options(query) {
      if (!this.periods?.length) {
        return [];
      }

      let res_periods = [...this.periods];

      if (query?.length) {
        res_periods = this.periods.filter(item => item.name.toLowerCase().includes(query?.toLowerCase()));
      }

      return res_periods.map(item => ({ label: item.name, value: item.id }));
    },

    async fetch_company_options(query) {
      if (!this.companies?.length) {
        return [];
      }

      let res_companies = [...this.companies];

      if (query?.length) {
        res_companies = this.companies.filter(item => item.name.toLowerCase().includes(query?.toLowerCase()));
      }

      return res_companies.map(item => ({ label: item.name, value: item.company_id }));
    },

    startJobClicked() {
      this.$refs['confirmJob'].show();
    },
    onSubmit(){},
    onJobConfirmed() {
      axios
        .put(`/spar/job/start/${this.form.id}`, { })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), 'SPAR Körningen har startats');
            return;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte starta körning');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte starta körning');
        });
    },
    selectSPARJob(id) {
      this.form = this.spar_jobs.find(item => item.id === id);
      this.showEditor = true;
    },
    newSPARJobClicked() {
      this.resetForm();
      this.showEditor = true;
    },
    loadSPARJobs() {
      axios
        .get(`/spar/jobs`)
        .then(res => {
          this.spar_jobs = res.data;
        })
        .catch(err => {
          console.error(err);
        });
    },

    resetForm() {
      this.form = {
        name: 'Ny körning',
        company_ids: [],
        period_ids: [],
        period_selection: 'SELECTION',
        company_selection: 'SELECTION',
        num_lookups_calc: 0,
        cost_sek: 0
      };
    },

    saveSPARJob() {
      

      if (this.form.id) {

        axios
          .put(`/spar/job/${this.form.id}`, this.form)
          .then(res => {
            if (res.status === 200) {
              this.form = res.data;

              const index = this.spar_jobs.findIndex(item => item.id === this.form.id);

              this.spar_jobs[index] = this.form;
              // Vue3 compatability for Vue.set
              this.spar_jobs = [...this.spar_jobs];

              this.isDisabledStart = false;
            }
          })
          .catch(err => {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera');
            console.error(err);
          });
      }
      else {
        axios
          .post(`/spar/job`, this.form)
          .then(res => {
            if (res.status === 201) {
              this.form = res.data;

              this.spar_jobs.push(this.form);

              this.isDisabledStart = false;
            }
          })
          .catch(err => {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa');
            console.error(err);
          });
      }

    },

  }
};
</script>


<style lang="css" scoped>

:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 150px;
}

</style>